export const bakalavrOchnaya = [
  "Информатика и вычислительная техника",
  "Атомные станции: проектирование, эксплуатация и инжиниринг",
  "Автоматизация технологических процессов и производств",
  "Водные биоресурсы и аквакультура",
  "Менеджмент",
  "Мехатроника и робототехника",
  "Прикладная информатика",
  "Прикладная математика",
  "Реклама и связи с общественностью",
  "Социология",
  "Теплоэнергетика и теплотехника",
  "Техносферная безопасность",
  "Экономика",
  "Электроника и наноэлектроника",
  "Электроэнергетика и электротехника",
  "Энергетическое машиностроение",
];

export const bakalavrZaochnaya = [
  "Автоматизация технологических процессов и производств",
  "Информатика и вычислительная техника",
  "Прикладная информатика",
  "Реклама и связи с общественностью",
  "Социология",
  "Теплоэнергетика и теплотехника",
  "Техносферная безопасность",
  "Электроэнергетика и электротехника",
];

export const bakalavrOchnoZaochnaya = ["Менеджмент", "Экономика"];

export const magistrOchnaya = [
  "Водные биоресурсы и аквакультура",
  "Информатика и вычислительная техника",
  "Менеджмент",
  "Мехатроника и робототехника",
  "Прикладная информатика",
  "Строительство",
  "Теплоэнергетика и теплотехника",
  "Электроника и наноэлектроника",
  "Электроэнергетика и электротехника",
];

export const magistrZaochnaya = [
  "Информатика и вычислительная техника",
  "Менеджмент",
  "Строительство",
  "Теплоэнергетика и теплотехника",
  "Техносферная безопасность",
  "Электроэнергетика и электротехника",
];

export const magistrOchnoZaochnaya = ["Менеджмент"];

export const data = [
  {
    key: 1,
    snils: "в00002382",
    typeIsp: null,
    sumBal_ID: 0,
    sumBal: 0,
    pred_1: null,
    pred_2: null,
    pred_3: null,
    pred_4: null,
    sumBal_OnlyID: 0,
    priority: 1,
    needRoom: null,
    receptionFeatures: "Отдельная квота",
    reasonForAdmission: "Бюджетная основа",
    admissionCategory: "Имеющие особое право",
    formStudy: "Заочная",
    napravlenie: "Электроэнергетика и электротехника",
    profil: "Электроэнергетические системы и сети",
    originalDiplom: null,
  },
  {
    key: 2,
    snils: "120-139-045 95",
    typeIsp: "ЕГЭ",
    sumBal_ID: 250,
    sumBal: 240,
    pred_1: 80,
    pred_2: 64,
    pred_3: 96,
    pred_4: null,
    sumBal_OnlyID: 10,
    priority: 3,
    needRoom: "Да",
    receptionFeatures: "Общие места",
    reasonForAdmission: "Бюджетная основа",
    admissionCategory: "Имеющие особое право",
    formStudy: "Очная",
    napravlenie: "Мехатроника и робототехника",
    profil: "Мехатроника",
    originalDiplom: "Да",
  },
  {
    key: 3,
    snils: "120-139-045 95",
    typeIsp: "ЕГЭ",
    sumBal_ID: 250,
    sumBal: 240,
    pred_1: 80,
    pred_2: 64,
    pred_3: 96,
    pred_4: null,
    sumBal_OnlyID: 10,
    priority: 1,
    needRoom: null,
    receptionFeatures: "Общие места",
    reasonForAdmission: "Бюджетная основа",
    admissionCategory: "Имеющие особое право",
    formStudy: "Очная",
    napravlenie: "Информатика и вычислительная техника",
    profil: "Технологии разработки информационных систем и web-приложений",
    originalDiplom: "Да",
  },
  {
    key: 4,
    snils: "120-139-045 95",
    typeIsp: "ЕГЭ",
    sumBal_ID: 250,
    sumBal: 240,
    pred_1: null,
    pred_2: 80,
    pred_3: 64,
    pred_4: 96,
    sumBal_OnlyID: 10,
    priority: 2,
    needRoom: "Да",
    receptionFeatures: "Общие места",
    reasonForAdmission: "Бюджетная основа",
    admissionCategory: "Имеющие особое право",
    formStudy: "Очная",
    napravlenie: "Прикладная математика",
    profil:
      "Математическое и программное обеспечение систем искусственного интеллекта",
    originalDiplom: null,
  },
  {
    key: 5,
    snils: "189-637-819 48",
    typeIsp: "ЕГЭ",
    sumBal_ID: 241,
    sumBal: 231,
    pred_1: 76,
    pred_2: 74,
    pred_3: 81,
    pred_4: null,
    sumBal_OnlyID: 10,
    priority: 4,
    needRoom: null,
    receptionFeatures: "Общие места",
    reasonForAdmission: "Бюджетная основа",
    admissionCategory: "Имеющие особое право",
    formStudy: "Очная",
    napravlenie: "Автоматизация технологических процессов и производств",
    profil: "Цифровые технологии машиностроения",
    originalDiplom: "Да",
  },
  {
    key: 6,
    snils: "189-637-819 48",
    typeIsp: "ЕГЭ",
    sumBal_ID: 241,
    sumBal: 231,
    pred_1: 76,
    pred_2: 74,
    pred_3: 81,
    pred_4: null,
    sumBal_OnlyID: 10,
    priority: 2,
    needRoom: "Да",
    receptionFeatures: "Общие места",
    reasonForAdmission: "Бюджетная основа",
    admissionCategory: "Имеющие особое право",
    formStudy: "Очная",
    napravlenie: "Мехатроника и робототехника",
    profil: "Мехатроника",
    originalDiplom: null,
  },
  {
    key: 7,
    snils: "189-637-819 48",
    typeIsp: "ЕГЭ",
    sumBal_ID: 241,
    sumBal: 231,
    pred_1: 76,
    pred_2: 74,
    pred_3: 81,
    pred_4: null,
    sumBal_OnlyID: 10,
    priority: 3,
    needRoom: null,
    receptionFeatures: "Общие места",
    reasonForAdmission: "Бюджетная основа",
    admissionCategory: "Имеющие особое право",
    formStudy: "Очная",
    napravlenie: "Электроника и наноэлектроника",
    profil: "Материалы и технологии электроники",
    originalDiplom: "Да",
  },
  {
    key: 8,
    snils: "189-637-819 48",
    typeIsp: "ЕГЭ",
    sumBal_ID: 241,
    sumBal: 231,
    pred_1: 76,
    pred_2: 74,
    pred_3: 81,
    pred_4: null,
    sumBal_OnlyID: 10,
    priority: 1,
    needRoom: "Да",
    receptionFeatures: "Общие места",
    reasonForAdmission: "Бюджетная основа",
    admissionCategory: "Имеющие особое право",
    formStudy: "Очная",
    napravlenie: "Электроэнергетика и электротехника",
    profil: "Электромобильный беспилотный транспорт",
    originalDiplom: "Да",
  },
  {
    key: 9,
    snils: "189-637-819 48",
    typeIsp: "ЕГЭ",
    sumBal_ID: 241,
    sumBal: 231,
    pred_1: 76,
    pred_2: 74,
    pred_3: 81,
    pred_4: null,
    sumBal_OnlyID: 10,
    priority: 5,
    needRoom: null,
    receptionFeatures: "Общие места",
    reasonForAdmission: "Бюджетная основа",
    admissionCategory: "Имеющие особое право",
    formStudy: "Очная",
    napravlenie: "Атомные станции: проектирование, эксплуатация и инжиниринг",
    profil: "Проектирование и эксплуатация атомных станций",
    originalDiplom: null,
  },
  {
    key: 10,
    snils: "166-074-063 64",
    typeIsp: "ЕГЭ",
    sumBal_ID: 237,
    sumBal: 237,
    pred_1: 76,
    pred_2: 88,
    pred_3: 73,
    pred_4: null,
    sumBal_OnlyID: 0,
    priority: 1,
    needRoom: "Да",
    receptionFeatures: "Общие места",
    reasonForAdmission: "Бюджетная основа",
    admissionCategory: "Имеющие особое право",
    formStudy: "Очная",
    napravlenie: "Информатика и вычислительная техника",
    profil: "Технологии разработки программного обеспечения",
    originalDiplom: null,
  },
  {
    key: 11,
    snils: "в00002382",
    typeIsp: null,
    sumBal_ID: 0,
    sumBal: 0,
    pred_1: null,
    pred_2: null,
    pred_3: null,
    pred_4: null,
    sumBal_OnlyID: 0,
    priority: 1,
    needRoom: null,
    receptionFeatures: "Отдельная квота",
    reasonForAdmission: "Бюджетная основа",
    admissionCategory: "Имеющие особое право",
    formStudy: "Заочная",
    napravlenie: "Электроэнергетика и электротехника",
    profil: "Электроэнергетические системы и сети",
    originalDiplom: null,
  },
  {
    key: 12,
    snils: "120-139-045 95",
    typeIsp: "ЕГЭ",
    sumBal_ID: 250,
    sumBal: 240,
    pred_1: 80,
    pred_2: 64,
    pred_3: 96,
    pred_4: null,
    sumBal_OnlyID: 10,
    priority: 3,
    needRoom: "Да",
    receptionFeatures: "Общие места",
    reasonForAdmission: "Бюджетная основа",
    admissionCategory: "Имеющие особое право",
    formStudy: "Очная",
    napravlenie: "Мехатроника и робототехника",
    profil: "Мехатроника",
    originalDiplom: "Да",
  },
  {
    key: 13,
    snils: "120-139-045 95",
    typeIsp: "ЕГЭ",
    sumBal_ID: 250,
    sumBal: 240,
    pred_1: 80,
    pred_2: 64,
    pred_3: 96,
    pred_4: null,
    sumBal_OnlyID: 10,
    priority: 1,
    needRoom: null,
    receptionFeatures: "Общие места",
    reasonForAdmission: "Бюджетная основа",
    admissionCategory: "Имеющие особое право",
    formStudy: "Очная",
    napravlenie: "Информатика и вычислительная техника",
    profil: "Технологии разработки информационных систем и web-приложений",
    originalDiplom: "Да",
  },
  {
    key: 14,
    snils: "120-139-045 95",
    typeIsp: "ЕГЭ",
    sumBal_ID: 250,
    sumBal: 240,
    pred_1: null,
    pred_2: 80,
    pred_3: 64,
    pred_4: 96,
    sumBal_OnlyID: 10,
    priority: 2,
    needRoom: "Да",
    receptionFeatures: "Общие места",
    reasonForAdmission: "Бюджетная основа",
    admissionCategory: "Имеющие особое право",
    formStudy: "Очная",
    napravlenie: "Прикладная математика",
    profil:
      "Математическое и программное обеспечение систем искусственного интеллекта",
    originalDiplom: null,
  },
  {
    key: 15,
    snils: "189-637-819 48",
    typeIsp: "ЕГЭ",
    sumBal_ID: 241,
    sumBal: 231,
    pred_1: 76,
    pred_2: 74,
    pred_3: 81,
    pred_4: null,
    sumBal_OnlyID: 10,
    priority: 4,
    needRoom: null,
    receptionFeatures: "Общие места",
    reasonForAdmission: "Бюджетная основа",
    admissionCategory: "Имеющие особое право",
    formStudy: "Очная",
    napravlenie: "Автоматизация технологических процессов и производств",
    profil: "Цифровые технологии машиностроения",
    originalDiplom: "Да",
  },
  {
    key: 16,
    snils: "189-637-819 48",
    typeIsp: "ЕГЭ",
    sumBal_ID: 241,
    sumBal: 231,
    pred_1: 76,
    pred_2: 74,
    pred_3: 81,
    pred_4: null,
    sumBal_OnlyID: 10,
    priority: 2,
    needRoom: "Да",
    receptionFeatures: "Общие места",
    reasonForAdmission: "Бюджетная основа",
    admissionCategory: "Имеющие особое право",
    formStudy: "Очная",
    napravlenie: "Мехатроника и робототехника",
    profil: "Мехатроника",
    originalDiplom: null,
  },
  {
    key: 17,
    snils: "189-637-819 48",
    typeIsp: "ЕГЭ",
    sumBal_ID: 241,
    sumBal: 231,
    pred_1: 76,
    pred_2: 74,
    pred_3: 81,
    pred_4: null,
    sumBal_OnlyID: 10,
    priority: 3,
    needRoom: null,
    receptionFeatures: "Общие места",
    reasonForAdmission: "Бюджетная основа",
    admissionCategory: "Имеющие особое право",
    formStudy: "Очная",
    napravlenie: "Электроника и наноэлектроника",
    profil: "Материалы и технологии электроники",
    originalDiplom: "Да",
  },
  {
    key: 18,
    snils: "189-637-819 48",
    typeIsp: "ЕГЭ",
    sumBal_ID: 241,
    sumBal: 231,
    pred_1: 76,
    pred_2: 74,
    pred_3: 81,
    pred_4: null,
    sumBal_OnlyID: 10,
    priority: 1,
    needRoom: "Да",
    receptionFeatures: "Общие места",
    reasonForAdmission: "Бюджетная основа",
    admissionCategory: "Имеющие особое право",
    formStudy: "Очная",
    napravlenie: "Электроэнергетика и электротехника",
    profil: "Электромобильный беспилотный транспорт",
    originalDiplom: "Да",
  },
  {
    key: 19,
    snils: "189-637-819 48",
    typeIsp: "ЕГЭ",
    sumBal_ID: 241,
    sumBal: 231,
    pred_1: 76,
    pred_2: 74,
    pred_3: 81,
    pred_4: null,
    sumBal_OnlyID: 10,
    priority: 5,
    needRoom: null,
    receptionFeatures: "Общие места",
    reasonForAdmission: "Бюджетная основа",
    admissionCategory: "Имеющие особое право",
    formStudy: "Очная",
    napravlenie: "Атомные станции: проектирование, эксплуатация и инжиниринг",
    profil: "Проектирование и эксплуатация атомных станций",
    originalDiplom: null,
  },
  {
    key: 20,
    snils: "166-074-063 64",
    typeIsp: "ЕГЭ",
    sumBal_ID: 237,
    sumBal: 237,
    pred_1: 76,
    pred_2: 88,
    pred_3: 73,
    pred_4: null,
    sumBal_OnlyID: 0,
    priority: 1,
    needRoom: "Да",
    receptionFeatures: "Общие места",
    reasonForAdmission: "Бюджетная основа",
    admissionCategory: "Имеющие особое право",
    formStudy: "Очная",
    napravlenie: "Информатика и вычислительная техника",
    profil: "Технологии разработки программного обеспечения",
    originalDiplom: null,
  },
];
